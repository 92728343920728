button {
    border: 0;
    border-radius: 4px;
    outline: 0;
    transition: color .2s linear, outline .15s ease-in-out;

    &:focus-visible {
        outline: 4px solid $color__primary--disabled;
    }

    &.button {
        appearance: none;
    }
}
