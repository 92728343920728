.splide {
    &__track {

    }

    &__list {

    }

    &__slide {

    }

    &__footer {

        .banner.banner--slider &.banner__footer{

            @include media-breakpoint-down(md) {
                position: relative;
                z-index: 1;
                right: auto;
                bottom: auto;
                left: auto;
                height: 4rem;

                &::before{
                    display: none;
                }
            }
        }
    }

    &__navigation {

    }

    &__arrows {

        .splide__toggle{
            background-color: $color__primary--hover;

            @include hover-focus-visible {
                background-color: $color__primary;
            }
        }
    }

    &__arrow {
        background-color: $color__primary--hover;

        @include hover-focus-visible {
            background-color: $color__primary;
        }
    }

    &__toggle {

    }

    &__pagination {

        .splide:not(.splide--custom) & li button{

            &::before{
                border-color: $color__primary--hover;
            }

            @include hover-focus-visible {

                &::before{
                    background-color: $color__primary--hover;
                }
            }

            &.is-active::before {
                background-color: $color__primary;
                border-color: $color__primary;
              }
        }
    }
}
